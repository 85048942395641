import React from "react";
import styled from "styled-components";

const Label = styled.label`
  position: relative;
  width: 44px;
  height: 12px;
  display: inline-block;

  & > span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6e7ea;
    transition: 0.4s;
    border-radius: 8px;

    &::before {
      position: absolute;
      content: "";
      width: 28px;
      height: 28px;
      border-radius: 16px;
      box-shadow: 0 2px 9px 0 rgba(72, 69, 88, 0);
      border: solid 1px #d8def2;
      background-color: #fff;
      transition: 0.3s ease-out;
      top: -8px;
      left: -8px;
    }

    &::after {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 16px;
      background-color: #fff;
      transition: 0.3s ease-out;
    }
  }

  & > input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span::before {
      transform: translateX(30px);
    }

    &:checked + span::after {
      transform: translateX(30px);
      background-color: #08132f;
    }

    &:checked:disabled + span::after {
      background-color: #e6e7ea;
    }

    &:disabled + span {
      cursor: not-allowed;
    }
  }
`;

export const ToggleSpan = styled.span`
  &.disabled {
    color: ${({ theme }) => `${theme.colors["GRAY_800"]}`};
    opacity: 0.3;
  }
`;

interface ToggleInputProps {
  disabled?: boolean;
  handleToggle: any;
  checked?: boolean;
  defaultValue?: string;
  value?: string | number | readonly string[];
}

function ToggleInput({ handleToggle, ...rest }: ToggleInputProps) {
  return (
    <Label>
      <input
        disabled={rest.disabled}
        type="checkbox"
        onChange={handleToggle}
        value={rest.value}
        {...rest}
      />
      <span className="slicer" />
    </Label>
  );
}

export default ToggleInput;
