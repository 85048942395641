import styled, { css } from "styled-components";

interface ButtonProps {
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  transparent?: boolean;
  danger?: boolean;
  fullWidth?: boolean;
  theme: any;
}

const Button = styled.button<ButtonProps>`
  align-self: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: block;
  font-family: ${({ theme }) => theme.fonts["body"]};
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;

  letter-spacing: 0.05em;
  vertical-align: baseline;
  outline: 2px solid transparent;
  outline-offset: 2px;
  overflow: hidden;
  font-size: 1.125rem;
  line-height: 1.75rem;

  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: 0 2px 9px rgba(72, 69, 88, 0);

  transition-duration: 250ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: background-color, color, box-shadow;
  transition-property: border, background-color, color, box-shadow, transform;

  width: ${(props) => (props.fullWidth ? "100%" : "")};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }

  ${getStyles};
`;

export default Button;

function getStyles({
  primary,
  secondary,
  tertiary,
  transparent,
  danger,
  theme,
}: ButtonProps) {
  let styles;
  if (secondary) {
    styles = css`
      background-color: ${theme.colors["INDIGO_100"]};
      color: ${theme.colors["primary"]};

      &:not(:disabled):hover,
      &:not(:disabled):focus {
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);
        outline: 2px solid transparent;
        outline-offset: 2px;
      }

      &:not(:disabled):active {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      }

      &:not(:disabled):focus {
        box-shadow: 0 0 0 3px ${theme.colors["INDIGO_300"]};
      }
    `;
  } else if (primary) {
    styles = css`
      background-color: ${theme.colors["INDIGO_100"]};
      color: ${theme.colors["primary"]};

      &:not(:disabled):hover,
      &:not(:disabled):focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
      }

      &:not(:disabled):active {
        background-color: ${theme.colors["INDIGO_200"]};
        box-shadow: 0 0 0 3px ${theme.colors["INDIGO_300"]};
        color: ${theme.colors["INDIGO_1000"]};
      }

      &:not(:disabled):focus {
        box-shadow: 0 0 0 3px ${theme.colors["INDIGO_300"]};
      }
    `;
  } else if (tertiary) {
    styles = css`
      color: ${theme.colors["GRAY_700"]};
      transition-duration: 100ms;

      &:not(:disabled):hover,
      &:not(:disabled):focus {
        outline: 2px solid transparent;
        border: 2px solid ${theme.colors["INDIGO_300"]};
        outline-offset: 2px;
      }

      &:not(:disabled):active {
        box-shadow: 0 0 0 3px ${theme.colors["GRAY_400"]};
        border: 2px solid ${theme.colors["INDIGO_300"]};
      }

      &:not(:disabled):focus {
        box-shadow: 0 0 0 3px ${theme.colors["GRAY_400"]};
      }
    `;
  } else if (transparent) {
    styles = css`
      padding: 0.625rem 1.825rem 0.625rem 1rem;
      color: ${theme.colors["primary"]};
      display: flex;
      align-items: center;
    `;
  } else if (danger) {
    styles = css`
      background-color: ${theme.colors["RED_100"]};
      color: ${theme.colors["RED_700"]};

      &:not(:disabled):hover,
      &:not(:disabled):focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
      }

      &:not(:disabled):active {
        background-color: ${theme.colors["RED_200"]};
        box-shadow: 0 0 0 3px ${theme.colors["RED_400"]};
        color: ${theme.colors["RED_800"]};
      }

      &:not(:disabled):focus {
        box-shadow: 0 0 0 3px ${theme.colors["RED_400"]};
      }
    `;
  }

  return styles;
}
