import styled, { css } from "styled-components";

function getColumnGutterCSS(value: number, withVerticalGutter: boolean) {
  return css`
    margin-right: -${value}px;
    margin-left: -${value}px;

    ${withVerticalGutter &&
    css`
      margin-bottom: -${value * 2}px;
    `}

    & > * {
      padding-right: ${value}px;
      padding-left: ${value}px;

      ${withVerticalGutter &&
      css`
        padding-bottom: ${value * 2}px;
      `}
    }
  `;
}

export const Flex = styled.div<{
  align?: "flex-start" | "center" | "flex-end" | "unset";
  justify?: "start" | "space-between" | "space-around" | "flex-end" | "center";
  wrap?: "wrap" | "no-wrap";
  padding?: string;
  gutter?: number;
  withVerticalGutter?: boolean;
}>`
  display: flex;
  align-items: ${({ align }) => align || "start"};
  justify-content: ${({ justify }) => justify || "start"};
  ${({ padding }) => (padding ? `padding: ${padding}` : "")};
  flex-wrap: ${({ wrap }) => wrap || "no-wrap"};
  ${({ gutter, withVerticalGutter }) =>
    gutter && getColumnGutterCSS(gutter, !!withVerticalGutter)}
`;

export default {
  Row: styled(Flex)`
    flex-direction: row;
    width: 100%;
  `,
  Column: styled(Flex)`
    flex-direction: column;
  `,
};
