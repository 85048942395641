import React from "react";
import styled, { css } from "styled-components";
import media from "../../../lib/theme/media";

interface ContainerProps {
  wide?: boolean;
}

export const ContainerRow = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Container = styled.div<ContainerProps>`
  position: relative;
  max-width: 100%;
  width: 100%;
  padding: 0 21px;

  ${({ wide }) => containerDefaults({ wide })};
`;

export const containerDefaults = ({ wide }: { wide?: boolean }) => css`
  max-width: 100%;
  width: 100%;
  padding: 0 21px;

  @media ${media.small} {
    padding: 0 21px;
  }

  @media ${media.medium} {
    padding: 0 5vw;
  }

  @media ${media.large} {
    padding: ${wide ? "0 5vw" : "0 10vw"};
  }

  @media ${media.xLarge} {
    padding: ${wide ? "0 7vw" : "0 12vw"};
  }

  @media ${media.xxLarge} {
    padding: ${wide
      ? "0 calc((100vw - 1400px) / 2)"
      : "0 calc((100vw - 1200px) / 2)"};
  }
`;

export default function ContainerComponent({
  children,
  wide,
  ...rest
}: {
  children: React.ReactNode;
  wide?: boolean;
  style?: any;
}): JSX.Element {
  return (
    <Container wide={wide}>
      <ContainerRow {...rest}>{children}</ContainerRow>
    </Container>
  );
}
