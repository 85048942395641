import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {
    font-family: "museo-sans", "Kulim Park", sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    font-family: "Kulim Park", sans-serif;
  }

  header {
    font-family: "Kulim Park", sans-serif;
  }

  a {
    text-decoration: none;
    font-family: inherit;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
`;
